import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap"

const Header = ({ siteTitle, data }) => (
  <header>
    <div>
      <Navbar bg="light" expand="lg" className="bg-white">
        <Container fluid>
          <Link to="/" className="link-no-style">
            <Navbar.Brand>
              <Img
                //className="home-services"
                style={{ height: "32px", width: "128px" }}
                fluid={data.logo.childImageSharp.fluid}
                alt="PuntoHost"
                imgStyle={{ objectFit: "contain" }}
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Hosting" id="Hosting">
                <Link to="/web-hosting/" className="ax">
                  <NavDropdown.Item as="li" eventKey="Web Hosting">
                    Web Hosting
                  </NavDropdown.Item>
                </Link>
                <Link to="/reseller-hosting/" className="ax">
                  <NavDropdown.Item as="li" eventKey="Reseller Hosting">
                    Reseller Hosting
                  </NavDropdown.Item>
                </Link>
              </NavDropdown>
              <Nav.Link href="https://my.puntohost.com/order/config/preconfig/dominios">
                Dominios
              </Nav.Link>
              <NavDropdown title="Streaming" id="Streaming">
                <Link to="/audio-streaming/" className="ax">
                  <NavDropdown.Item as="li" eventKey="Audio Streaming">
                    Audio Streaming
                  </NavDropdown.Item>
                </Link>
                <Link to="/reseller-streaming/" className="ax">
                  <NavDropdown.Item as="li" eventKey="Reseller Streaming">
                    Reseller Streaming
                  </NavDropdown.Item>
                </Link>
              </NavDropdown>

              <NavDropdown title="Servidores" id="Servidores">
                <Link to="/servidores-vps/" className="ax">
                  <NavDropdown.Item as="li" eventKey="VPS">
                    VPS
                  </NavDropdown.Item>
                </Link>
                <Link to="/servidores-dedicados/" className="ax">
                  <NavDropdown.Item as="a" eventKey="Dedicados">
                    Dedicados
                  </NavDropdown.Item>
                </Link>
              </NavDropdown>
              <Nav.Link href="https://my.puntohost.com/client/plugin/support_manager/client_tickets/add/5/">
                Contáctenos
              </Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link
                href="https://my.puntohost.com/client/login/"
                className="text-dark"
              >
                Iniciar sesión
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default function MyHeader(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          logo: file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => <Header data={data} {...props} />}
    />
  )
}
