import { Link } from "gatsby"
import React from "react"
import { Container, Row } from "react-bootstrap"

const Footer = ({ children }) => (
  <footer>
    <div className="footer-top">
      <Container>
        <Row>
          <div className="col-md-6 col-lg-2 footer_widget">
            <p className="s-title">Servicios</p>
            <ul>
              <li>
                <a href="https://portal.puntohost.com/cart.php?a=add&domain=register&currency=2">
                  Dominios
                </a>
              </li>
              <li>
                <Link to="/web-hosting/">Web Hosting</Link>
              </li>
              <li>
                <Link to="/audio-streaming/">Audio Streaming</Link>
              </li>
              <li>
                <Link to="/servidores-vps/">Servidores VPS</Link>
              </li>
              <li>
                <Link to="/servidores-dedicados/">Servidores dedicados</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-2 footer_widget">
            <p>Revendedores</p>
            <ul>
              <li>
                <Link to="/reseller-hosting/">Reseller Hosting</Link>
              </li>
              <li>
                <Link to="/reseller-streaming/">Reseller Streaming</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-2 footer_widget">
            <p>Asistencia técnica</p>
            <ul>
              <li>
                <a href="https://my.puntohost.com/plugin/support_manager/client_tickets/">
                  Ticket a soporte
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-2 footer_widget">
            <p>Recursos</p>
            <ul>
              {/* <li>
                <Link to="#">WHOIS</Link>
              </li> */}
              <li>
                <Link to="/cualesmiip/">Su IP p&uacute;blica</Link>
              </li>
              <li>
                <a href="https://status.puntohost.com">Estado de la red</a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-2 footer_widget">
            <p>Cuenta</p>
            <ul>
              <li>
                <a href="https://my.puntohost.com/client/login/">Mi cuenta</a>
              </li>
              <li>
                <a href="https://my.puntohost.com/client/invoices/index/open/">
                  Mis renovaciones
                </a>
              </li>
              {/* <li>
                <a href="https://portal.puntohost.com/register.php">
                  Crear cuenta
                </a>
              </li>*/}
            </ul>
          </div>
          <div className="col-md-6 col-lg-2 footer_widget">
            <p>PuntoHost</p>
            <ul>
              <li>
                <a href="/reviews/">Testimonios</a>
              </li>
              <li>
                <a href="https://my.puntohost.com/order/affiliates/signup/">
                  Programa de afiliados
                </a>
              </li>
              <li>
                <Link to="/terminos-condiciones/">
                  T&eacute;rminos y condiciones
                </Link>
              </li>
              <li>
                <Link to="/politicas-de-privacidad/">
                  Pol&iacute;ticas de privacidad
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      </Container>
    </div>
    <div className="footer-bottom">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="copyright-txt">
              Derechos reservados &copy; {new Date().getFullYear()},
              PuntoHost.com
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
