/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, schemaMarkup }) {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title | `${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${pathname}`,
        },
        {
          property: `og:site_name`,
          content: `${site.siteMetadata.title}`,
        },
        {
          property: `fb:app_id`,
          content: `671776139647415`,
        },
        {
          property: "og:image:width",
          content: "1200px",
        },
        {
          property: "og:image:height",
          content: "628px",
        },
        {
          property: "og:image",
          content: `${site.siteMetadata.siteUrl}/fbimage_1200x628.png`,
        },
        {
          name: `twitter:title`,
          content: title | `${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: "twitter:image",
          content: `${site.siteMetadata.siteUrl}/fbimage_1200x628.png`,
        },
        {
          property: `twitter:url`,
          content: `${site.siteMetadata.siteUrl}${pathname}`,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: "google-site-verification",
          content: "kEPi9UIqCTNRtWNLIsShmlyOQJPn8z1_j08vLt6GZ5c",
        },
        {
          name: "msvalidate.01",
          content: "7DF2A9F9AC3ACC32787C31780AFE05BE",
        },
        {
          name: "yandex-verification",
          content: "608b6f07b10fd68e",
        },
      ].concat(meta)}
      link={[
        {
          rel: "alternate",
          content: `${site.siteMetadata.siteUrl}${pathname}`,
          hreflang: "es-VE",
        },
        {
          rel: "alternate",
          content: `${site.siteMetadata.siteUrl}${pathname}`,
          hreflang: "x-default",
        },
        {
          rel: "sitemap",
          type: "application/xml",
          href: `${site.siteMetadata.siteUrl}/sitemap.xml`,
        },
      ]}
    >
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  //url: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
